
//Custom Components
import ProductCard from "@/components/ProductCard.vue"

// Utils
import utilities from "../utilities";

//Axios
import axios from "axios";

import { defineComponent } from 'vue'

export default defineComponent({
    name: "SearchBar",
    components: {
        ProductCard
    },
    data() {
        return {
            isSearching: false,
            showSearchBox: false,
            products: [] as any[any],
            searchText: ''
        }
    },
    computed: {
        utilities: () => {
            return utilities
        }
    },

    methods: {
        async search() {
            if (this.searchText.length > 3) {
                this.products = []
                this.isSearching = true

                this.toggleSearch(true)

                //get products
                const products = await axios.get(window.location.protocol + '//admin.bupy.store/api/items/products?limit=4&fields=*.*,variations.*,variations.gallery.*.*&filter[name][like]=' + this.searchText + '&filter[category.name][logical]=or&filter[category.name][like]=' + this.searchText )
                this.products = products.data.data

                this.isSearching = false
            }
            else {
                this.toggleSearch(false)
            }
        },
        cleanSearch() {
            this.toggleSearch(false);
            this.searchText = ''
            setTimeout(() => {
                this.products = []
            }, 300)
        },
        toggleSearch(show: boolean) {
            setTimeout(() => {
                if (this.searchText.length > 3 && show) {
                    this.showSearchBox = true
                }
                else {
                    this.showSearchBox = false
                }
            }, 500)
        }
    }
})
