//axios
import axios from 'axios'
//router
import router from './router';
//store
import store from './store';

// set apiUrl
const apiUrl = /*(process.env.NODE_ENV === 'production')
  ?*/ window.location.protocol + '//admin.bupy.store/api'
  //: window.location.protocol + '//admin.bupy.local/api'

const utils = {
  formatPrice: function (price: number, applyExchange = true) {
    let exchangeRate = 1
    let localeString = 'es-PY'
    let minimumFractionDigits = 0

    if (store.state.currency == 'USD') {
      localeString = 'en-US'
      exchangeRate = store.state.config.exchange_rate
      minimumFractionDigits = 2
    }

    const formattingOptions = {
      style: 'currency',
      currency: store.state.currency as any,
      minimumFractionDigits: minimumFractionDigits,
    }

    let formattedPrice = new Intl.NumberFormat(localeString, formattingOptions)
    if (!applyExchange) {
      exchangeRate = 1
    }

    formattedPrice = formattedPrice.format(price / exchangeRate) as any
    return formattedPrice
  },
  getItems: async function (request: string, token = '', returnTo = '/') {

    const endpoint = (token != '')
      ? apiUrl + request + '&access_token=' + token
      : apiUrl + request

    try {
      const response = await axios.get(endpoint);

      if (response.status == 200) {
        return response.data.data
      }
    }
    catch (error:any) {
      console.error(error);
      if (error.response && error.response.status === 401) {
        router.push('/login?to='+returnTo);
      }
    }
  },
  auth: async function (email: string, password: string, onError: any, returnTo: string) {
    const endpoint = apiUrl + '/auth/authenticate'

    const response = await axios.post(endpoint, { 'email': email, 'password': password })
      .catch(error => {
        const errorCode = error.response.data.error.code

        if (errorCode == 100) {
          onError('Datos Invalidos')
        } else if (errorCode == 103) {
          onError('Cuenta Inactiva')
        } else {
          onError('Hubo un error. Intente nuevamente.')
        }
      }) as any

    if (response && response.status == 200) {
      //set user data
      store.commit('setUserData', response.data.data.user)
      store.commit('setToken', response.data.data.token)
      if (returnTo) {
        router.push(returnTo)
      }
    }
  },
  /*checkAuth: async function (backTo = '') {

    if (store.state.token) {
      // gte current route
      const currentRoute = router.currentRoute.value.path

      // get token
      const token = store.state.token

      const response: any[any] = await axios.get(
        apiUrl + '/users/me?access_token=' + token,
      )
        .catch(error => {
          if (error.response.status == 401) {
            //remove old token
            store.state.token = null

            // push to log in back
            if (currentRoute !== '/login') {
              if (backTo != '') {
                router.push('/login?to=' + backTo)
              }
            }
          } else {
            console.log('Error de servidor')
          }
        })

      // VALID TOKEN
      return {
        token: token,
        data: response.data.data
      }
    } else {
      // no token
      if (backTo != '') {
        router.push('/login?to=' + backTo)
      }
    }
  },*/
  checkForm: function (form: FormData) {
    let empyInputs = true

    Array.from(form).forEach((input: any[any]) => {
      if (input.required && input.value === '' && empyInputs) {
        console.log(input.name)
        empyInputs = false
      }
    })

    return empyInputs
  },
  createAccount: async function (firstName: string, lastName: string, email: string, password: string, onError: any) {
    const endpoint = apiUrl + '/items/users'
    const response = await axios.post(endpoint,
      {
        "status": "active",
        "first_name": firstName,
        "last_name": lastName,
        "email": email,
        "password": password
      }
    ).catch(error => {
      onError(error.response.data.error.message)
      throw error.response.data.error.message
    })

    return 'ok'
  },
  resetPassword: async function (email: string) {
    const endpoint = apiUrl + '/auth/password/request'
    const response = await axios.post(endpoint,
      {
        "email": email
      }
    )

    if (response.status == 200) {
      return 'sent'
    } else {
      return 'error'
    }
  },
  setPassword: async function (password: string, token: string) {
    const endpoint = apiUrl + '/auth/password/reset'
    const response = await axios.post(endpoint,
      {
        "token": token,
        "password": password
      }
    )

    if (response.status == 200) {
      return 'ok'
    } else {
      return 'error'
    }
  },
  saveUserAddress: async function (addressData: any, token: string) {
    const endpoint = apiUrl + '/items/users_addresses?fields=*&access_token=' + token

    const response = await axios.post(endpoint, addressData)

    return response.data.data
  },
  deleteUserAddress: async function (addressId: number) {
    const endpoint = apiUrl + '/items/users_addresses/'+addressId+'?fields=*&access_token=' + store.state.token

    const response = await axios.delete(endpoint)

    return response.status
  },
  createOrder: async function (orderData: any, token: string) {
    const endpoint = apiUrl + '/items/orders?fields=*&access_token=' + token

    const response = await axios.post(endpoint, orderData)

    return response.data.data
  },
  createPaymentHash: async function (orderData: any) {
    const endpoint = apiUrl + '/custom/bancard/vpos/pay'

    const response = await axios.post(endpoint, orderData)

    return response.data
  },
  getTranslation(key: string): string {
    if (Object.prototype.hasOwnProperty.call(store.state.translation,key)) {
      return (store.state.translation as any)[key]
    }
  
    return key;
  }
}

export default utils

