import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/categories',
    name: 'categories',
    component: () => import('../views/CategoriesView.vue')
  },
  {
    path: '/categories/:id',
    name: 'category',
    component: () => import('../views/CategoryView.vue')
  },
  {
    path: '/product/:id',
    name: 'product',
    component: () => import('../views/ItemView.vue')
  },
  {
    path: '/cart',
    name: 'cart',
    component: () => import('../views/Cart.vue')
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: () => import('../views/Checkout.vue')
  },
  {
    path: '/pay',
    name: 'pay',
    component: () => import('../views/Pay.vue')
  },
  {
    path: '/payment',
    name: 'payment',
    component: () => import('../views/Payment.vue')
  },
  {
    path: '/help',
    name: 'help',
    component: () => import('../views/HelpCenter.vue')
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import('../views/Account.vue'),
    children: [
      {
        path: '',
        redirect: '/account/orders',
      },
      {
        path: 'orders',
        component: () => import('../views/AccountSingles/Orders.vue'),
      },
      {
        path: 'addresses',
        component: () => import('../views/AccountSingles/Addresses.vue'),
      },
      {
        path: 'my-info',
        component: () => import('../views/AccountSingles/MyInfo.vue'),
      }
    ]
  },
  {
    path: '/orders',
    name: 'Orders',
    component: () => import('../views/Orders.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      if (savedPosition) {
          return resolve(savedPosition);
      } else {
        if( !to.path.includes('account') || !from.path.includes('account')){
          return resolve({ top: 0 });
        }
      }
    })
  }
})

export default router
