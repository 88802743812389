import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "banner w-full aspect-video bg-gray-300 animate-pulse rounded-md overflow-hidden shadow-lg lg:aspect-[3/1]"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($props.image != null)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "banner w-full aspect-video bg-gray-300 bg-cover bg-center rounded-md overflow-hidden shadow-lg lg:aspect-[3/1]",
        style: _normalizeStyle(($props.image) ? { backgroundImage: 'url(' + $props.image + ')' } : '')
      }, null, 4))
    : (_openBlock(), _createElementBlock("div", _hoisted_1))
}