
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: "iconComponent",
  props: {
    iconImg: String,
    color: {
      type: String,
      default: 'bg-black'
    },
    size: {
      type: String,
      default: 'small'
    }
  },
  methods:{
    getStyle(){
      if (this.size === 'small') {
        return this.color+" w-5 h-5";
      } else if (this.size === 'medium') {
        return this.color+" w-12 h-12";
      } else if (this.size === 'large') {
        return this.color+" w-24 h-24";
      }
    }
  }
});
