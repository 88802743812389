import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["overlay h-full fixed top-0 left-0 bg-black opacity-30 z-40", (_ctx.isOpen) ? 'w-full' :'w-0'])
    }, null, 2),
    _createElementVNode("div", {
      class: _normalizeClass(["sideBar w-full md:w-1/2 lg:w-1/4 h-screen fixed top-0 text-sm bg-white z-50 transition-all duration-300", (_ctx.isOpen) ? 'left-0' :'-left-full'])
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 2)
  ], 64))
}