import { createStore } from 'vuex'

//vuex persist
import vuexPersistance from 'vuex-persist';

export default createStore({
  state: {
    webAppVersion: 0,
    pageData: {
      categories: []
    },
    language: "en",
    currency: "USD",
    translation: {},
    config: [] as any[any],
    user: [] as any[any],
    token: null,
    orderId: null,
    order: {
      status: 'pending',
      products: [] as any[any],
      shipping: 0,
      total: 0,
      first_name: '',
      last_name: '',
      street: '',
      number: '',
      apt_suite: '',
      region: 0,
      country: 0,
      state: 0,
      city: '',
      zip_code: '',
      weight: 0
    },
    cartTotalItems: 0
  },
  getters: {
  },
  mutations: {
    setTranslation(state, translation) {
      state.translation = translation
    },
    setLanguage(state, language) {
      state.language = language
    },
    setCurrency(state, currency) {
      state.currency = currency
    },
    setToken(state, token) {
      state.token = token
    },
    setUserData(state, userData) {
      state.user = userData
    },
    resetStore(state) {
      state.user = []
      state.token = null
      state.order = {
        status: 'pending',
        products: [] as any[any],
        shipping: 0,
        total: 0,
        first_name: '',
        last_name: '',
        street: '',
        number: '',
        apt_suite: '',
        region: 0,
        country: 0,
        state: 0,
        city: '',
        zip_code: '',
        weight: 0
      }
      state.cartTotalItems = 0
    },
    addProduct(state, newProduct) {
      let alreadyInCart = 0

      console.log(newProduct)

      if (state.order.products.length > 0) {
        state.order.products.forEach((product: any, index: number) => {
          if (product.variation_id == newProduct.variation_id && product.subvariation_index == newProduct.subvariation_index) {
            alreadyInCart = 1
            state.order.products[index].quantity+= newProduct.quantity
            state.cartTotalItems += newProduct.quantity
          }
        })
      }

      if (alreadyInCart == 0) {
        state.order.products.push(newProduct)
        state.cartTotalItems += newProduct.quantity
      }
    },
    removeProduct(state, index) {
      state.cartTotalItems -= state.order.products[index].quantity
      state.order.products.splice(index, 1)
    },
    addAddress(state, address) {
      state.order.first_name = address.first_name
      state.order.last_name = address.last_name
      state.order.street = address.street
      state.order.number = address.number
      state.order.apt_suite = address.apt_suite
      state.order.region = address.region.name
      state.order.country = address.country.name
      state.order.state = address.state.name
      state.order.city = address.city
      state.order.zip_code = address.zip_code
    },
    setOrderId(state, id) {
      state.orderId = id
    }
  },
  actions: {
  },
  modules: {
  }
  ,
  plugins: [
    new vuexPersistance({
      storage: window.localStorage
    }).plugin
  ]
})
