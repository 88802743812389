
//custom components
import Button from '@/components/Button.vue'

//view
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'LanguageCurrency',
    components:{
        Button
    },
    data() {
        return {
            selectedLanguage: this.$store.state.language,
            selectedCurrency: this.$store.state.currency,
            showDialog: false,
            languages: {
                es:
                {
                    label: 'Idioma',
                    options: [
                        {
                            label: 'Español',
                            value: 'es'
                        },
                        {
                            label: 'Inglés',
                            value: 'en'
                        }
                    ]
                }

            },
            currencies: {
                es:
                {
                    label: 'Moneda',
                    options: [
                        {
                            label: 'Guaranies',
                            value: 'PYG'
                        },
                        {
                            label: 'Dólares',
                            value: 'USD'
                        }
                    ]
                }
            }
        }
    },
    methods:{
        toggleDialog(){
            //reset language
            if(this.selectedLanguage != this.$store.state.language){
                this.selectedLanguage = this.$store.state.language
            }

            //reset currency
            if(this.selectedCurrency != this.$store.state.currency){
                this.selectedCurrency = this.$store.state.currency
            }

            //toggle dialog
            this.showDialog = !this.showDialog
        },
        changeSettings(){
            //save language
            this.$store.commit('setLanguage', this.selectedLanguage)

            //save currency
            this.$store.commit('setCurrency', this.selectedCurrency)

            //toggle dialog
            this.showDialog = false

            location.reload()
        }
    }
})
