
//Custom Components
import Banner from "@/components/BannerComponent.vue"
import ProductCard from "@/components/ProductCard.vue"

//Axios
import axios from "axios";

// Utils
import utilities from "../utilities";

// Swiper
import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

//This View
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'HomeView',
  data() {
    return {
      banners: [] as any[any],
      categories: [] as any[any],
      sections: [] as any[any],
      config: [] as any[any]
    }
  },
  components: {
    Banner,
    ProductCard
  },
  computed:{
    utilities: ()=> {
       return utilities
    }
  },
  async created() {
    //get sections
    const sections = await axios.get(window.location.protocol + '//admin.bupy.store/api/items/sections?fields=*,products.*,products.variations.*,products.variations.gallery.directus_files_id.data.*')

    //featured sections
    this.sections = sections.data.data

    //init section swiper
    this.sections.forEach( (section:any) => {
     this.$nextTick( () => {
      this.initSectionSwiper('section-'+section.id)
     })
    });
    
    //get categories
    const categories = await axios.get(window.location.protocol + '//admin.bupy.store/api/items/categories?fields=*.*&limit=6')
    this.categories = categories.data.data
  
    //create banners swiper
    const bannersOpts = {
      slidesPerView: 1.095,
      centeredSlides: true,
      spaceBetween: 0,
      speed: 500,
      loop: true,
      autoplay: {
        delay: 5000,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      modules: [Autoplay, Navigation, Pagination],
      pagination: {
          el: '.swiper-pagination'
      },
      breakpoints: {
        768: {
          slidesPerView: 1.065
        }
      }
    }

    this.$nextTick( () => {
      const bannersSwiper = new Swiper(this.$refs.banners as any, bannersOpts)
    })
  },
  methods: {
    initSectionSwiper(section:string) {
      //create featured swiper
      const featuredOpts = {
        slidesPerView: 2.1,
        spaceBetween: 16,

        modules: [Navigation],
        breakpoints: {
          768: {
            slidesPerView: 6,
          }
        }
      }

      new Swiper(this.$refs[section] as any, featuredOpts)
    }
  }
});
