

import { defineComponent } from 'vue'

export default defineComponent({
    name: "ProductCard",
    props:{
        product: {} as any[any],
        price: String,
        mode:{
            type:String,
            default: 'vertical'
        }
    }
})
