import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

//tailwind
import './tailwind/index.css'

store.state.webAppVersion = process.env.VUE_APP_VERSION;

console.log(process.env.VUE_APP_VERSION)

//create app
createApp(App).use(store).use(router).mount('#app')
