
//update
import update from './update'

// Utils
import utilities from "./utilities";

//Axios
import axios from "axios";

//custom components
import NavBar from '@/components/NavBar.vue'
import SideBar from '@/components/SideBar.vue'
import SearchBar from '@/components/SearchBar.vue'
import Icon from "@/components/Icon.vue"
import LanguageCurrency from "@/components/LanguageCurrency.vue"

import { defineComponent } from 'vue'

export default defineComponent({
  name: 'HomeView',
  components: {
    NavBar,
    SideBar,
    SearchBar,
    Icon,
    LanguageCurrency
  },
  data() {
    return {
      token: '',
      sideBarOpen: false,
      showSearchBox: false,
      showLanguageCurrencyDialog: false,
      menuOptions: [
        {
          link: '/',
          text: this.$store.state.translation.app ? this.$store.state.translation.app.menu.home : 'home',
          icon: '/img/icons/home.svg',
        },
        {
          link: '/categories',
          text: this.$store.state.translation.app ? this.$store.state.translation.app.menu.categories : 'categories',
          icon: '/img/icons/layout-grid.svg'
        },
        {
          link: '/account',
          text: this.$store.state.translation.app ? this.$store.state.translation.app.menu.userAccount : 'userAccount',
          icon: '/img/icons/shield-user.svg'
        },
        {
          link: '/help',
          text: this.$store.state.translation.app ? this.$store.state.translation.app.menu.helpCenter : 'helpCenter',
          icon: '/img/icons/chat-smile-3.svg'
        }
      ]
    }
  },
  mixins: [update],
  computed: {
    utilities: () => {
      return utilities
    }
  },
  async created() {
    //reset translation
    this.$store.commit('setTranslation', {})

    //get translation
    const response = await axios.get("/translation." + this.$store.state.language + ".json")

    //set translations
    if (Object.keys(response.data).length > 0) {
      this.$store.commit('setTranslation', response.data)
    }

    //get config
    const config = await axios.get(window.location.protocol + '//admin.bupy.store/api/items/config/1?fields=*.*.*')
    this.$store.state.config = config.data.data
  },
  methods: {
    toggleSideBar() {
      this.sideBarOpen = !this.sideBarOpen
    },
    changeLanguageCurrency() {
      this.showLanguageCurrencyDialog = !this.showLanguageCurrencyDialog
    }
  }
})
